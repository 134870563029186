import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { CATEGORY_MENU_PARAMS } from '@wix/communities-blog-client-common';

const { showAllPostsCategory, showPostCount } = CATEGORY_MENU_PARAMS;

export const getShowAllPostsCategory = state =>
  getAppSettingsValue({
    state,
    key: showAllPostsCategory.appSettingsPath,
    fallback: showAllPostsCategory.defaultValue,
  });

export const getShowPostCount = state =>
  getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });
